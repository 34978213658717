import { Box, Link, Typography } from '@mui/material';
import TitlePic from '../../../components/TitlePic';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TextSection from '../../../components/TextSection';

const Qualifikation = () => {
  const qualifikationen = [
    {
      img: '/media/quality/EE.webp',
      name: 'Energieeffizienz Experte',
      url: 'https://www.energie-effizienz-experten.de',
      description: 'Energieeffizienz Experte',
    },
    {
      img: '/media/quality/GIH.webp',
      name: 'Interessenvertretung für Energieberatende',
      url: 'https://www.energie-effizienz-experten.de',
      description: 'Interessenvertretung für Energieberatende',
    },
    {
      img: '/media/quality/hwk-logo.svg',
      name: 'Handwerkskammer der Pfalz',
      url: 'https://www.hwk-pfalz.de',
      description: 'Handwerkskammer der Pfalz',
    },
  ];

  return (
    <Box className="h-full w-full flex flex-col">
      <TitlePic
        title="Zertifizierte Qualität"
        upperText="Geprüfte Expertise und anerkannte Mitgliedschaften"
        src={{
          webp: '/media/hero/PFLEGER_BAUSERVICE_HERO-5.webp',
          jpg: '/media/hero/PFLEGER_BAUSERVICE_HERO-5.jpg',
        }}
      />

      <TextSection
        title="Offiziell anerkannt & bestens vernetzt"
        text="Unser Energieberater ist Mitglied in führenden Fachverbänden und in der Expertenliste der Energieeffizienz-Experten für Förderprogramme des Bundes gelistet. Diese Zertifizierungen garantieren höchste Fachkompetenz und Qualität in der Energieberatung."
      />
      <Box className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6 w-full  gap-10 p-8 xl:p-4">
        {qualifikationen.map((partner, index) => (
          <Qualitifkation
            key={index}
            imgSrc={partner.img}
            name={partner.name}
            href={partner.url}
            description={partner.description}
          />
        ))}
      </Box>
    </Box>
  );
};

const Qualitifkation = ({ imgSrc, name, description, href }) => {
  return (
    <Box className="text-lg text-center flex flex-col justify-start items-center">
      <img src={imgSrc} className=" aspect-square w-56 object-contain" />
      <Typography variant="h4" textAlign="center">
        {description}
      </Typography>
    </Box>
  );
};

export default Qualifikation;
