import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Box } from '@mui/material';
import Sidebar from './navigation/Sidebar';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Topbar from './navigation/Topbar';
import { BrowserRouter } from 'react-router-dom';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from './theme/theme';
import { useState } from 'react';
import Projects from './pages/projects';
import Contact from './pages/contact';
import Impressum from './pages/impressum';
import Privacy from './pages/privacy';
import MobileMenu from './navigation/MobileMenu';
import Div100vh from 'react-div-100vh';
import Footer from './navigation/Footer';
import AboutUs from './pages/about-us';
import CookieConsentBanner from './components/cookies/CookieConsentBanner';
import { Analytics } from './components/Analytics';
import { EnergieExperte } from './pages/energieexperte';

function App() {
  const [variant, setVariant] = useState('bauwesen');
  const [theme, colorMode] = useMode();

  const [currentLocation, setCurrentLocation] = useState('/');

  function handleLocationChange(newLocation) {
    setCurrentLocation(newLocation);
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <Analytics
        src={'https://analytics.das-innovations.de/script.js'}
        websiteId={'e2902cc2-683d-4907-8e11-412c585426e0'}
      />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Div100vh>
            <CookieConsentBanner />
            <Box className=" w-full h-full flex flex-row overflow-hidden">
              <Sidebar
                location={currentLocation}
                onLocationChange={handleLocationChange}
                onVariantChange={setVariant}
                variant={variant}
              />
              <Box className="h-full w-full overflow-hidden flex flex-col ">
                <Topbar />
                <MobileMenu
                  location={currentLocation}
                  onLocationChange={handleLocationChange}
                  onVariantChange={setVariant}
                  variant={variant}
                />

                <Box
                  id="main"
                  className="overflow-y-auto overflow-x-hidden h-full xl:snap-y xl:snap-mandatory"
                >
                  <Routes>
                    <Route
                      path="*"
                      element={
                        <Home
                          onLocationChange={handleLocationChange}
                          onVariantChange={setVariant}
                        />
                      }
                    />
                    <Route path="/projekte" element={<Projects />} />
                    <Route path="/kontakt" element={<Contact />} />
                    <Route path="/impressum" element={<Impressum />} />
                    <Route path="/datenschutz" element={<Privacy />} />
                    <Route path="/über-uns" element={<AboutUs />} />
                    <Route
                      path="/energie-qualifikation"
                      element={
                        <EnergieExperte
                          onLocationChange={handleLocationChange}
                          onVariantChange={setVariant}
                        />
                      }
                    />
                    <Route
                      path="/energie-experte"
                      element={
                        <EnergieExperte
                          onLocationChange={handleLocationChange}
                          onVariantChange={setVariant}
                        />
                      }
                    />
                    <Route
                      path="/energie-beratung"
                      element={
                        <EnergieExperte
                          onLocationChange={handleLocationChange}
                          onVariantChange={setVariant}
                        />
                      }
                    />
                    <Route
                      path="/sanierungsfahrplan"
                      element={
                        <EnergieExperte
                          onLocationChange={handleLocationChange}
                          onVariantChange={setVariant}
                        />
                      }
                    />
                    <Route
                      path="/baubegleitung"
                      element={
                        <EnergieExperte
                          onLocationChange={handleLocationChange}
                          onVariantChange={setVariant}
                        />
                      }
                    />
                    <Route
                      path="/foerdermittel"
                      element={
                        <EnergieExperte
                          onLocationChange={handleLocationChange}
                          onVariantChange={setVariant}
                        />
                      }
                    />
                    <Route
                      path="/energieausweise"
                      element={
                        <EnergieExperte
                          onLocationChange={handleLocationChange}
                          onVariantChange={setVariant}
                        />
                      }
                    />
                  </Routes>
                  <Footer
                    onLocationChange={handleLocationChange}
                    variant={variant}
                  />
                </Box>
              </Box>
            </Box>
          </Div100vh>
        </BrowserRouter>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
